import * as React from "react";

// markup
const NotFoundPage = () => (
  <main>
    <title>ded</title>
    <h1>ded</h1>
  </main>
);

export default NotFoundPage;
